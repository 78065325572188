import { API_ROUTES } from "../utils/constant"
import api from './api'

export function storeInLocalStorage(token) {
    localStorage.setItem('token', token)
}

export function getFromLocalStorage(item) {
    return localStorage.getItem(item)
  }
  
export async function getAuthenticatedUser() {
    const defaultReturnObject = { authenticated: false, user: null }
    try {
        const token = getFromLocalStorage('token')
        if (!token) {
            return defaultReturnObject
        }
        const response = await api.get(API_ROUTES.GET_USER)
        return { authenticated: true, user: response.data }
    } catch (err) {
        console.error('getAuthenticatedUser, Something Went Wrong', err)
        return defaultReturnObject
    }
}