import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash, faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import Button from "../Button"
import { useEffect, useState } from "react"
import axios from "axios"
import { API_ROUTES } from "../../utils/constant"
import { useNavigate } from "react-router-dom"

// #region CSS
const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
`

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 40px 10px 40px;
    border-radius: 25px;
    max-height: auto;
    background-color: white;
    min-height: 230px;
    ${MediaMobile} {
        padding: 5px;
        padding-top: 45px;
    }
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
    ${MediaMobile} {
        right: 5px;
    }
`

const MainTitle = styled.h2`
    text-align: center;
`

const FormLabelContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const InputContainer = styled.input`
    width: 400px;
    height: 30px;
    border-radius: 25px;
    padding: 5px 20px;
    background-color: ${colors.background};
    border: 1px solid transparent;
    outline: none;
    position: relative;
    ${MediaMobile} {
        width: 300px;
    }
`

const ShowButton = styled.button`
    border: none;
    cursor: pointer;
    color: ${colors.buttonBis};
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 25%;
`

const ShowIcon = styled(FontAwesomeIcon)`
    font-size: ${fontSize.icon};
`

// #endregion CSS

function ModalResetPassword ({setCloseModal, token}) {
    const navigate = useNavigate()

    const [isDisabled, setIsDisabled] = useState(true)
    const [password, setPassword] = useState('')
    const [confirmatioPassword, setConfirmationPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [notification, setNotification] = useState({ error: false, message: '' })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(password !== confirmatioPassword) {
            setNotification({error: true, message:'* Les mots de passe ne correspondent pas'})
            return
        }
        try{
            const response = await axios.post(`${API_ROUTES.RESET_PASSWORD}/${token}`, {password}, {withCredentials: true})
            if(response.status === 201) {
                const successMessage = <div><FontAwesomeIcon icon={faCircleCheck}/> Votre mot de passe a été modifié avec succès</div>                  
                setNotification({error: false, message: successMessage})
                setTimeout(() => {
                    navigate('/')
                }, 3000)
            } else {
                setNotification({error: true, message: '* Le lien est invalide ou expiré'})
            }
        } catch (err) {
            setNotification({error: true, message: '* Une erreur est survenue'})
        }
    }

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const validateForm = () => {
        const hasInformation = password.trim() !== "" && confirmatioPassword.trim() !== ""
        return hasInformation
    }

    useEffect(() => {
        setIsDisabled(!validateForm())
    }, [confirmatioPassword, password])    

    return (
        <MainContainer>
            <ModalContainer>
                <FontIconClose icon={faXmark} onClick={() => setCloseModal(false)}/>
                <div>
                    <MainTitle>Réinitialiser votre mot de passe</MainTitle>
                    <FormLabelContainer onSubmit={handleSubmit}>
                        <div style={{position: 'relative'}}>
                            <InputContainer 
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Nouveau mot de passe" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                            />
                            <ShowButton onClick={handlePasswordVisibility}>{showPassword ? <ShowIcon icon={faEye} /> : <ShowIcon icon={faEyeSlash} />}</ShowButton>
                        </div>
                        <div style={{position: 'relative'}}>
                            <InputContainer 
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Confirmation du nouveau mot de passe" 
                                value={confirmatioPassword}
                                onChange={(e) => setConfirmationPassword(e.target.value)} 
                            />
                            <ShowButton onClick={handlePasswordVisibility}>{showPassword ? <ShowIcon icon={faEye} /> : <ShowIcon icon={faEyeSlash} />}</ShowButton>
                        </div>
                        <Button 
                            type="submit"
                            name="Réinitialiser"
                            disabled={isDisabled} 
                            className={isDisabled ? "selected" : "" }
                        />
                    </FormLabelContainer>
                    <div style={notification.error === true ? {color: `${colors.pinkLady}`} : {color: `${colors.blueJeans}`}}>{notification.message}</div>
                </div>
            </ModalContainer>
        </MainContainer>
    )
}

export default ModalResetPassword