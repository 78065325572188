import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../../utils/constant"
import axios from "axios"
import Button from "../Button"
import { useAuth } from "../../lib/commonHooks"

// #region CS
const MainTitle = styled.h2`
    text-align: center;
`

const FormLabelContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const InputContainer = styled.input`
    width: 400px;
    height: 30px;
    border-radius: 25px;
    padding: 5px 20px;
    background-color: ${colors.background};
    border: 1px solid transparent;
    outline: none;
    position: relative;
    ${MediaMobile} {
        width: 300px;
    }
`

const ShowButton = styled.button`
    border: none;
    cursor: pointer;
    color: ${colors.text};
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 25%;
`

const ShowIcon = styled(FontAwesomeIcon)`
    font-size: ${fontSize.icon};
`
// #endregion CSS

function Connexion({setOpenModal}) {
    const {handleLogin} = useAuth()

    const [showPassword, setShowPassword] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [notification, setNotification] = useState({ error: false, message: '' })

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const validateForm = () => {
        const hasInformation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim()) && password.trim() !== ""
        return hasInformation
    }

    const signIn = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(API_ROUTES.SIGN_IN, {email, password})
            if(response.status === 200 && response.data) {
                const {token, userId, email} = response.data
                await handleLogin(token, userId, email)
                setOpenModal(false)
                return
            } else {
                console.error({ error: true, message: 'Une erreur est survenue'})
                const errorMessage = response?.data?.message || '* Une erreur s\'est produite'
                setNotification({error: true, message: errorMessage})    
            }
        } catch (err) {
            console.error(err)
            console.log('une erreur là')
        }
    }

    useEffect(() => {
        setIsDisabled(!validateForm())
    }, [email, password])

    return(
        <>
            <MainTitle>Accès réservé aux utilisateurs du site !</MainTitle>
            <FormLabelContainer onSubmit={signIn}>
                <InputContainer 
                    type="email" 
                    name="email"  
                    placeholder="Votre e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} 
                    required
                />
                <div style={{position: 'relative'}}>
                    <InputContainer 
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Mot de passe" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} 
                        required
                    />
                    <ShowButton onClick={handlePasswordVisibility}>{showPassword ? <ShowIcon icon={faEye} /> : <ShowIcon icon={faEyeSlash} />}</ShowButton>
                </div>
                <div>{notification.message}</div>
                <Button 
                    type="submit"
                    name="Se connecter"
                    disabled={isDisabled} 
                    className={isDisabled ? "selected" : "" }
                />
            </FormLabelContainer>
        </>
    )
}

export default Connexion