import styled from "styled-components"
import { colors, MediaMobile } from "../style/GlobalStyle"
import TarifContainer from "../components/TarifContainer"
import PrestationContainer from "../components/PrestationContainer"
import BookingContainer from "../components/BookingContainer"
import AboutContainer from "../components/AboutContainer"
import ModalConnexion from "../components/ModalConnexion"
import { useState, useEffect } from "react"
import ModalResetPassword from "../components/connexion_components/ModalResetPassword"
import { useSearchParams } from "react-router-dom"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    align-items: center;
    ${MediaMobile} {
        margin-bottom: 0;
        gap: 20px;
    }
`

const SousContainer = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
`

// #endregion CSS

function HomePage ({openModal, setOpenModal}) {
    const [closeModal, setCloseModal] = useState(false)
    const [searchParams] = useSearchParams()
    const reset = searchParams.get("reset")
    const token = searchParams.get("token")

    useEffect(() => {
        if (reset === "true") {
            setCloseModal(true)
        }
    }, [reset])

    return(
        <MainContainer>
            <SousContainer id="prestation">
                <PrestationContainer />
            </SousContainer>
            <SousContainer id="tarif">
                <TarifContainer /> 
            </SousContainer>
            <SousContainer id="booking">
                <BookingContainer />
            </SousContainer>
            <SousContainer id="about">
               <AboutContainer />
            </SousContainer>
            {openModal && (
                <ModalConnexion
                    setOpenModal={setOpenModal}
                />
            )}
            {closeModal && (
                <ModalResetPassword token={token} setCloseModal={setCloseModal}/>
            )}
        </MainContainer>
    )
}

export default HomePage