import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Connexion from "./connexion_components/ConnexionForm"
import ForgotPassword from "./connexion_components/ForgotPassword"

// #region CSS
const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
`

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 40px 10px 40px;
    border-radius: 25px;
    max-height: auto;
    background-color: white;
    min-height: 230px;
    ${MediaMobile} {
        padding: 5px;
        padding-top: 45px;
    }
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
    ${MediaMobile} {
        right: 5px;
    }
`

const FieldContainer = styled(motion.div)`
    position: relative;
    height: 100%;
    & p {
        text-align: center;
        &: hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    top: -40px;
    left: -20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
    ${MediaMobile} {
        left: 0;
        top: -45px;
    }

`


// #endregion CSS

function ModalConnexion ({setOpenModal}) {
        const [step, setStep] = useState(1)
        const [direction, setDirection] = useState(1)
    
        const variants = {
            enter: (direction) => ({x: direction > 0 ? 40 : -40,opacity: 0}),
            center: { x: 0, opacity: 1 },
            exit: (direction) => ({x: direction > 0 ? -40 : 40,opacity: 0}),
        }
    
        const nextStep = () => {
            setDirection(1)
            setStep((prev) => prev + 1)
        }
        const prevStep = () => {
            setDirection(-1)
            setStep((prev) => prev - 1)
        }

    return(
        <MainContainer>
            <ModalContainer>
                <FontIconClose icon={faXmark} onClick={() => setOpenModal(false)}/>
                <div>
                    <AnimatePresence custom={direction} mode="wait">
                        {step === 1 && (
                            <FieldContainer
                                key="step1"
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >
                                <Connexion 
                                    setOpenModal={setOpenModal}
                                />
                                <p onClick={nextStep}>Mot de passe oublié ?</p>
                            </FieldContainer>
                        )}
                        {step === 2 && (
                            <FieldContainer
                                key="step2"
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >
                                <FontIcon icon={faChevronLeft} onClick={prevStep}/>                        
                                <ForgotPassword />
                            </FieldContainer>
                        )}
                    </AnimatePresence>
                </div>

            </ModalContainer>


        </MainContainer>
    )
}

export default ModalConnexion