import styled from "styled-components"
import { colors, MediaMobile } from "../style/GlobalStyle"

const MainContainer = styled.button`
    border: 1px solid transparent;
    background-color: ${colors.pinkLady};
    color: white;
    padding: 10px 60px;
    border-radius: 25px;
    cursor: pointer;
    &: hover{
        border: 1px solid ${colors.pinkLady};
        background-color: white;
        color: ${colors.pinkLady};
    }
    &.selected{
        opacity: 0.3;
        cursor: not-allowed;
    }
    ${MediaMobile} {
        padding: 10px 30px;
    }
`

function Button ({type, name, onClick, className}) {
    return(
        <MainContainer type={type} onClick={onClick} className={className}>
            {name}
        </MainContainer>
    )
}

export default Button