import { useState, useEffect, createContext, useContext } from "react"
import { getAuthenticatedUser, storeInLocalStorage } from "./common"

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'))
    const [connectedUser, setConnectedUser] = useState(null)
    const [userLoading, setUserLoading] = useState(true)
    const [error, setError] = useState(null)
    
  
    useEffect(() => {
      async function getUserDetails() {
        try {
          const { authenticated, user } = await getAuthenticatedUser()
          setConnectedUser(user)
          setIsAuthenticated(authenticated)
        } catch (err) {
          setError(err)
          setIsAuthenticated(false)
          setConnectedUser(null)
        } finally {
          setUserLoading(false)
        }
      }
      getUserDetails()

      const handleStorageChange = () => {
        if (!localStorage.getItem('token')) {
          setIsAuthenticated(false)
          setConnectedUser(null)
        }
      }
      
        window.addEventListener('storage', handleStorageChange)
        return () => window.removeEventListener('storage', handleStorageChange)
    }, [])

  
    // Gestion de connexion
    const handleLogin = (token, userId, email) => {
      try {
        storeInLocalStorage(token, userId, email)
        setIsAuthenticated(true)
        setConnectedUser({userId, token, email})
      } catch (err) {
        setError(err)
      }
    }

    // Gestion de déconnexion
    const handleLogout = () => {
      localStorage.removeItem('token')
      setIsAuthenticated(false)
      setConnectedUser(null)
      window.location.href = '/'
    }

    // Gestion d'expiration

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          connectedUser,
          userLoading,
          error,
          handleLogin,
          handleLogout
        }}
      >
        {children}
      </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)