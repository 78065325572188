import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faUser, faUserSlash } from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "../lib/commonHooks"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 100px;
    & div p {
        margin: 10px;
        color: ${colors.text};
    }
    ${MediaMobile} {
        margin-bottom: 20px;
        flex-direction: column;
        height: 280px;
    }
`

const AdressContainer = styled.div`
    position: absolute;
    left: 60px;
    text-align: left;
    ${MediaMobile} {
        left: 27%;
        top: 0;
        text-align: center;
    }
`

const LogoContainer = styled.div`
    ${MediaMobile} {
        display: none;
    }
`

const ContactContainer = styled.div`
    position: absolute;
    right: 60px;
    text-align: right;
    ${MediaMobile} {
        right: 10px;
        bottom: 0;
        text-align: center;
    }
`

const IconContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    ${MediaMobile} {
        padding-right: 100px;
    }
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    cursor: pointer;
`
// #endregion CSS

function Footer ({setOpenModal}) {
    const {isAuthenticated, handleLogout} = useAuth()

    const disconnect = () => {
        handleLogout()
    }
    return(
        <MainContainer>
            <AdressContainer>
                <p>155 route de Saint-Vrain</p>
                <p>91 760 ITTEVILLE</p>

                <p>Où vous garer ?</p>
                <p>Impasse du moulin</p>
                <p>91 760 ITTEVILLE</p>
            </AdressContainer>
            <LogoContainer>
                <img src="./assets/595B9A9B-A6CA-48CB-BC61-D2E0D2BEA6D7-removebg-preview.png" alt="logo"/>
            </LogoContainer>
            <ContactContainer>
                <p>Tel: 06.69.97.42.97</p>
                <p>Lundi, mardi, mercredi & vendredi : 10h00 - 19h30</p>
                <p>Jeudi : Fermé</p>
                <p>Samedi : 09h00 - 17h00</p>
                <IconContainer>
                    {!isAuthenticated ? (
                        <FontIcon icon={faUser} onClick={() => setOpenModal(true)}/>
                    ):(
                        <FontIcon icon={faUserSlash} onClick={disconnect}/>
                    )}
                    <a href="https://www.facebook.com/profile.php?id=100086431569687" aria-label="facebook" target="blank"><FontIcon icon={faFacebookF} /></a>
                    <a href="https://www.instagram.com/volteface__/" aria-label="instagram" target="blank"><FontIcon icon={faInstagram} /></a>
                </IconContainer>            
            </ContactContainer>
        
        </MainContainer>
    )
}

export default Footer