import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { useState, useEffect } from "react"
import axios from "axios"
import api from "../../lib/api"
import { API_ROUTES } from "../../utils/constant"
import { getAuthenticatedUser } from "../../lib/common"
import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import "react-big-calendar/lib/css/react-big-calendar.css"
import fr from "date-fns/locale/fr"

// #region CSS
const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
`

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 10px 40px;
    border-radius: 25px;
    background-color: white;
    min-height: auto;
    width: 1000px;
    text-align: center;
    margin: 0;
    & h2 {
        font-size: ${fontSize.h2};
    }
    ${MediaMobile} {
        width: 100%;
        padding: 40px 5px 10px 5px;
        border-radius: 0;
    }    
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
    ${MediaMobile} {
        top: 10px;
    }    
`

const CalendarComposant = styled(Calendar)`
    margin: 30px 20px!important;
    & .rbc-toolbar {
        margin-bottom: 20px;
        & .rbc-toolbar-label {
            font-family: "Cinzel", serif;
            color: ${colors.pinkLady};
            font-weight: bold;
            font-size: ${fontSize.h2};
        }
        & .rbc-btn-group {
            display: flex;
            flex-direction: row;
            gap: 5px;
            & .rbc-btn-group > button:not(:first-child):not(:last-child) {
                border-radius: 25px;
            }
            & button {
                border: 1px solid transparent;
                color: ${colors.text};
                &: hover {
                    background-color: ${colors.blueJeans};
                    border: 1px solid transparent;
                    border-radius: 25px;
                    color: white;
                }
                &: focus {
                    box-shadow: none;
                    background-color: ${colors.background};
                    border-radius: 25px;
                    color: ${colors.text};
                }
            }
            & button.rbc-active {
                box-shadow: none;
                background-color: ${colors.background};
                border-radius: 25px;
                color: ${colors.text};
                &: focus{
                    border-color: transparent;
                }
                &: hover {
                    background-color: ${colors.blueJeans};
                    border: 1px solid transparent;
                    border-radius: 25px;
                    color: white;
                }
            }
        }
    }
    & .rbc-month-view {
        color: ${colors.text};
        border: 1px solid transparent;
        & .rbc-header {
            text-transform: uppercase;
            border: 1px solid transparent;
            font-size: ${fontSize.p};
            ${MediaMobile} {
                font-size: ${fontSize.pMobile};
            }
        }
        & .rbc-month-row {
            border-top: 1px solid transparent;
            padding-right: 10px;
            padding-top: 5px;
            & .rbc-off-range-bg {
                background-color: transparent;
            }
            & .rbc-off-range {
                color: ${colors.brownSugar};
            }
        }
        & .rbc-day-bg {
            border-left: 1px solid transparent;
        }
        & .rbc-today {
            background-color: ${colors.background};
            border-radius: 25px;
            ${MediaMobile} {
                border-radius: 5px;
            }  
        }
        & .rbc-event {
            background-color: ${colors.pinkLady};
            border: 1px solid transparent;
            &: hover {
                background-color: white;
                border: 1px solid ${colors.pinkLady};
                color: ${colors.pinkLady};
            }
        }
        & .rbc-show-more {
            color: ${colors.pinkLady};
        }
    }
    & .rbc-day-slot .rbc-events-container {
        & .rbc-event {
            background-color: ${colors.pinkLady};
            border: 1px solid transparent;
            &: hover {
                background-color: white;
                border: 1px solid ${colors.pinkLady};
                color: ${colors.pinkLady};
            }
        }
    }
    & .rbc-time-view, .rbc-row-bg, .rbc-row {
        & .rbc-header, .rbc-day-bg {
            border: 1px solid transparent;
        }
        & .rbc-today {
            background-color: ${colors.background};
        }
        & .rbc-label {
            font-size: ${fontSize.p};
            padding: 0 8px;
            ${MediaMobile} {
                font-size: ${fontSize.pMobile};
            }

        }
        & .rbc-day-slot .rbc-event-content {
            font-size: ${fontSize.p};
            ${MediaMobile} {
                font-size: ${fontSize.pMobile};
            }
        }
    }
    & .rbc-agenda-view {
        & .rbc-header {
            color: ${colors.text};
            font-family: "Cinzel", serif;
            text-transform: uppercase;
            ${MediaMobile} {
                font-size: ${fontSize.pMobile};
            }
        }
    }
    & .rbc-current-time-indicator {
        background-color: ${colors.pinkLady};
    }
    & .rbc-agenda-event-cell, .rbc-agenda-time-cell, .rbc-agenda-date-cell {
        ${MediaMobile} {
            font-size: ${fontSize.pMobile};
        }
    }
`

const messages = {
    allDay: "Tous les jours",
    previous: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Evenement",
}

// #endregion CSS

function ModalCalendarView ({setCalendarViewModal}) {

    const [data, setData] = useState([])
    const [updateUI, setUpdateUI] = useState(false)

    const getFetchData = async () => {
        try {
            const response = await axios.get(API_ROUTES.BOOKING, {withCredentials: true})
            setData(response.data || [])
        } catch(err) {
            console.error(err)
        }
    }

    const handleDelete = async (id) => {
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            if (window.confirm('Etes-vous sûr de vouloir supprimer ?')) {
                await api.delete(`${API_ROUTES.BOOKING}/${id}`, {withCredentials: true})
                setUpdateUI((prevState) => !prevState)
            }
        } catch(err) {
            console.error(err)
        }
    }

    // Définition des éléments du calendrier
    const locales = {
        fr: fr
    }
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
    })
    
    const events = data.map((item) => ({
        id: item._id,
        title: ` ${item.name} - ${item.phone} - ${item.email}`,
        start: new Date(`${item.startDate}`),
        end: new Date(`${item.endDate}`),
    }))

    useEffect(() => {
        getFetchData()
    }, [updateUI])

    return(
        <MainContainer>
            <ModalContainer>
                <FontIconClose icon={faXmark} onClick={() => setCalendarViewModal(false)}/>
                <div>
                    <CalendarComposant 
                        min={new Date(0, 0, 0, 8, 0, 0)}
                        max={new Date(0, 0, 0, 22, 0, 0)}
                        localizer={localizer} 
                        events={events} 
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 600, margin: "50px" }}
                        culture="fr"
                        messages={messages}
                        onSelectEvent={(event) => handleDelete(event.id)}
                    />
                </div>
            </ModalContainer>


        </MainContainer>
    )
}

export default ModalCalendarView