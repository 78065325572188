import styled from "styled-components"
import { colors, MediaMobile } from "../style/GlobalStyle"
import { useEffect, useState } from "react"
import axios from "axios"
import { API_ROUTES } from "../utils/constant"
import { useAuth } from "../lib/commonHooks"
import ViewPrices from "./tarif_components/ViewPrices"
import EditedPrices from "./tarif_components/ViewEditedPrices"

// #region CSS
const MainContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 600px;
    background-color: white;
    ${MediaMobile} {
        height: auto;
        padding: 20px 0;
    }
`

const WaveTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    & svg {
        position: relative;
        display: block;
        width: 100%;
        & path {
            fill: ${colors.background};
        }
    }
`

const SousContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    ${MediaMobile} {
        display: flex;
        flex-direction: column;
    }
`

const ImageContainer = styled.div`
    height: 600px;
    align-content: center;
    justify-content: center;
    & img {
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
    ${MediaMobile} {
        display: none;
    }
`

const PricesMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & h2 {
        color: ${colors.pinkLady};
    }

`

// #endregion CSS


function TarifContainer () {
    const {isAuthenticated} = useAuth()
    const [updateUI, setUpdateUI] = useState(false)     
    const [data, setData] = useState([])

    // #region Récupération allData 
    const getAllPrices = async () => {
        try {
            const response = await axios.get(API_ROUTES.PRICES, {withCredentials: true})
            setData(response.data || [])
        } catch(err) {
            console.error(err)
        }
    }

    console.log("data", data)

    useEffect(() => {
        getAllPrices()
    }, [updateUI])
    // #endregion Récupération allData 


    return(
        <MainContainer>
            <WaveTop>
                <svg viewBox="0 10 1200 120" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0,60 C200,120 600,0 1200,45 L1200,0 L0,0 Z" ></path>
                </svg>
            </WaveTop>
            <SousContainer>
                <PricesMainContainer>
                    <h2>Mes tarifs</h2>
                    {!isAuthenticated ? (
                        <ViewPrices 
                            data={data}
                        />                    
                    ) : (
                        <EditedPrices 
                            data={data}
                            setData={setData} 
                            setUpdateUI={setUpdateUI}/>
                    )}
                </PricesMainContainer>
                <ImageContainer>
                    <img src="./assets/e8ebac2256240b9c756993c92137a92b.jpg" alt="tarifs"/>
                </ImageContainer>
            </SousContainer>
        </MainContainer>    
    )

}

export default TarifContainer