import styled from "styled-components"
import { colors, MediaMobile } from "../../style/GlobalStyle"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

const CalendarContainer = styled(Calendar)`
    &.react-calendar { 
        width: 80%;
        height: 350px;
        border-radius: 5px;
        border: none;
        ${MediaMobile} {
            width: 100%;
        }
    }
    & .react-calendar__navigation {
        display: flex;
        height: 55px;
        & button {
            color: ${colors.text};
            font-size: 16px;
            font-weight: bold;
            padding: 18px;
            font-family: "Cinzel", serif;
            &: hover {
                background: transparent;
            }
            ${MediaMobile} {
                padding: 5px;;
            }
        }
        & button span {
            font-family: "Cinzel", serif;
        }
        & button:disabled {
            background-color: transparent;
            color: transparent;
        }
        & button:enabled:hover, button:enabled:focus {
            background-color: transparent;
        }
      }
    & abbr[title] {
        text-decoration: none;
        color: ${colors.text};
    }
    & .react-calendar__tile--now {
        background: ${colors.background};
        border-radius: 25px;
        font-weight: bold;
    }
    & .react-calendar__tile--active, 
        .react-calendar__tile--rangeStart, 
        .react-calendar__tile--rangeEnd,
        .react-calendar__tile--active:enabled:focus {
            background: ${colors.blueJeans};
            border-radius: 25px;
            ${MediaMobile} {
                width: 40px;
                height: 40px;
                border-radius: 50px;
            }
    }
    &  .react-calendar__month-view__days__day:disabled,
        .react-calendar__year-view__months__month:disabled,
        .react-calendar__decade-view__years__year:disabled,
        .react-calendar__century-view__decades__decade:disabled{
            background-color: transparent;
            color: ${colors.brownSugar};
            cursor: not-allowed;
    }
    & .react-calendar__tile:enabled:hover {
        background-color: ${colors.blueJeans};
        border-radius: 25px;
        color: white;
    }
    & .react-calendar__tile--hasActive {
        background: transparent;
    }
`
function CalendarComponent ({date, handleDateChange}) {
    const isWeekEnd = ({ date }) => date.getDay() === 0 || date.getDay() === 4

    return(
        <CalendarContainer 
            onChange={handleDateChange}
            value={date}
            minDate={new Date()}
            tileDisabled={isWeekEnd}
            locale="fr-FR"
        />
    )
}

export default CalendarComponent