import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../style/GlobalStyle"
import { Link } from "react-scroll"

// #region CSS
const MainContainer = styled.div`
    position: relative;
    background-image: url('./assets/6a3705c51649ecede8c20add217d26ce.jpg');
    background-repeat: no-repeat;
    background-size: 100vw 75vw;
    height: 95vh;
    background-position: 50% 75%;
    & h1 {
        position: absolute;
        top: 20%;
        left: 40px;
        color: ${colors.background};
    }
    ${MediaMobile} {
        background-size: 140vw;
        height: 140vw;
        & h1 {
            top: 80%;
            text-align: center;
            left: 20px;
        }
    }
`

const MenuMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 20px;
    ${MediaMobile} {
        flex-direction: column;
        padding: 0;
    }
`
    
const MenuContainer = styled.nav`
    position: absolute;
    right: 40px;
    & ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        ${MediaMobile} {
            gap: 15px;
        }
        & a {
            text-decoration: none;
            ${MediaMobile} {
                font-size: 13px;
            }
        }
    }
    & ul li {
        list-style: none;
        cursor: pointer;
        color: ${colors.background};
    }
    ${MediaMobile} {
        top: 80%;
    }
`

const Wave = styled.div`
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: 10px;
    z-index: 1000;
    & svg {
        position: reltive;
        display: block;
        width: 100vw;
        & path {
            fill: ${colors.background};
        }
    }
`


// #endregion CSS

function Header () {

    return(
        <MainContainer>
            <MenuMainContainer>
                <img src="./assets/595B9A9B-A6CA-48CB-BC61-D2E0D2BEA6D7-removebg-preview.png" alt="logo"/>
                <MenuContainer>
                    <ul>
                        <li><Link to="prestation" smooth={true} duration={500}>Prestations</Link></li>
                        <li><Link to="tarif" smooth={true} duration={500}>Tarifs</Link></li>
                        <li><Link to="booking" smooth={true} duration={500}>Calendrier</Link></li>
                        <li><Link to="about" smooth={true} duration={500}>A propos</Link></li>
                    </ul>
                </MenuContainer>
            </MenuMainContainer>
            <h1>"Le bonheur au bout des doigts"</h1>
            <Wave>
                <svg viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0,60 C200,120 600,0 1200,45 L1200,0 L0,0 Z" ></path>
                </svg>
            </Wave>

        </MainContainer>
    )
}

export default Header