import styled from "styled-components"
import { colors, MediaMobile } from "../style/GlobalStyle"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
`

const PrestationMainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-gap: 100px;
    padding: 0 40px;
    & div {
        background-color: white;
        border-radius: 25%;
        height: 350px;
        position: relative;
        ${MediaMobile} {
            border-radius: 0;
        }
        & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 25%;
            ${MediaMobile} {
                border-radius: 0;
            }
        }
    }
    ${MediaMobile} {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 20px;
        padding: 0;
    }
`

const Overlay = styled.p`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25%;
    color: transparent;
    margin: 0;
    &: hover {
        background-color: rgba(0, 0, 0, 0.3);
        color: ${colors.background};
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }
    ${MediaMobile} {
        background-color: rgba(0, 0, 0, 0.1);
        color: ${colors.background};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
    } 
`
// #endregion CSS

function PrestationContainer () {
    return(
        <MainContainer>
            <h2>Les Prestations</h2>
            <PrestationMainContainer> 
                <div>
                    <img src="./assets/257ae110fc46a5aea2808ad9bb797d57.jpg" alt="extension"/>
                    <Overlay>Extensions</Overlay>                    
                </div>
                <div>
                    <img src="./assets/6ee6b84bddeaf1421637c7bf84d5e4d3.jpg" alt="nail art"/>
                    <Overlay>Nail Art</Overlay>                    
                </div>
                <div>
                    <img src="./assets/5633e71fe5a97494660b04c18fea63d8.jpg" alt="ongle naturel"/>
                    <Overlay>Ongles Naturels</Overlay>                    
                </div>
            </PrestationMainContainer>
        </MainContainer>
    )
}

export default PrestationContainer