import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../../utils/constant"
import { getAuthenticatedUser } from "../../lib/common"
import axios from "axios"
import api from "../../lib/api"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    ${MediaMobile} {
        padding: 0;
    }
`

const BarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 410px;
    ${MediaMobile} {
        width: 100%;
    }
`

const ListContainer = styled.div`
    position: absolute;
    top: 36px;
    left: 30px;
    width: 80%;
    z-index: 1;
    background-color: ${colors.background};
    border: 1px solid ${colors.background};
    border-top: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    will-change: max-height;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    &.open {
        padding: 10px 0;
        max-height: 200px;
    }
`

const Item = styled.div`
    cursor: pointer;
    ${MediaMobile} {
        font-size: ${fontSize.pMobile};
    }
`

const Input = styled.input`
    outline: none;
    background-color: ${colors.background};
    border-radius: 25px;
    border: 1px solid ${colors.background};
    padding: 10px;
`
    
const TagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

`

const TimeSheet = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 25px;
    padding: 10px;
    width: 80px;
    height: 15px;
    font-size: ${fontSize.p};
    background-color: white;
    color: ${colors.blueJeans};
    border: 1px solid ${colors.blueJeans};
    &: hover{
        background-color: ${colors.blueJeans};
        color: white;
        cursor: pointer;
    }
    ${MediaMobile} {
        width: 40px;
        font-size: ${fontSize.pMobile};
        padding: 5px;
    }
`

const FontIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
    ${MediaMobile} {
        color: transparent;
    }
`

// #endregion CSS

const timeSheet = [
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", 
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", 
    "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30"
]

function HourSlotEdited ({date}) {
    const [emptyData, setEmptyData] = useState([])
    const [data, setData] = useState([])
    const [tags, setTags] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [updateUI, setUpdateUI] = useState(false)

    const getFetchData = async () => {
        try {
            const [response, emptyResponse] = await Promise.all([
                axios.get(`${API_ROUTES.BOOKING}/${date}`, {withCredentials: true}),
                axios.get(`${API_ROUTES.BOOKING}/empty/${date}`, {withCredentials: true})
            ])
            setData(response?.data || [])
            setEmptyData(emptyResponse?.data || [])
            const availableHours = timeSheet.filter((hour) => !response.data.includes(hour))
            setTags(availableHours) 
        } catch(err) {
            console.error(err)
        }
    }

    console.log('data côté admin', data) // Donne les créneau indisponilbles
    console.log('emptydata côté admin', emptyData) // Donne les créneau indisponilbles
    console.log('tag côté admin', tags) // Montre ce que les clients voient
    
    // Gestion des utilisateurs
    const sortHours = emptyData.map(item => item.hour)
    .filter(item => typeof item === "string" && item.includes(":"))
    .sort((a, b) => {
        const [hourA, minA] = a.split(":").map(Number)
        const [hourB, minB] = b.split(":").map(Number)
        if (hourA !== hourB) return hourA - hourB
        return minA - minB
    })

    // Supprime les éléments de ListContainer
    const addedItem = async (hour) => {
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            await api.delete(`${API_ROUTES.BOOKING}/${date}/${hour}`, {withCredentials: true})
            setUpdateUI((prevState) => !prevState)
        } catch (err) {
            console.error(err)
        }
    }
    
    // Supprime les éléments de TagsContainer
    const deletedItem = async (hour) => {
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            const postDate = await api.post(`${API_ROUTES.BOOKING}/${date}/${hour}`, {date, hour}, {withCredentials: true})
            setUpdateUI((prevState) => !prevState)
            console.log("postDate", postDate)
        } catch (err) {
            console.error(err)
        }
    }
     
    useEffect(() => {
        getFetchData()
    }, [date, updateUI])

    return(
        <MainContainer>
            <BarContainer onMouseLeave={() => setIsOpen(false)}>
                <Input placeholder="-- Sélectionner un horaire" onMouseEnter={() => setIsOpen(true)} />
                <ListContainer className={isOpen && sortHours.length > 0 ? "open" : ""}>
                {isOpen && date && sortHours.map((item, index) =>
                    <Item key={`tagItem-${index}-${item}`} onClick={() => addedItem(item)}>{item}</Item>
                )}
                </ListContainer>
            </BarContainer>
            <TagsContainer>
                {date && (
                    tags.map((item, index) => 
                        <TimeSheet key={`tagItem-${index}-${item}`}>
                            {item}
                            <FontIcon icon={faXmark} onClick={() => deletedItem(item)}/>
                        </TimeSheet>
                    )
                )}
            </TagsContainer>
        </MainContainer>
    )
}

export default HourSlotEdited