import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import CalendarComponent from "./Calendar"
import HourSlotEdited from "./HourSlotEdited"

// #region CSS
const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
`

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 25px;
    background-color: white;
    min-height: auto;
    width: 1000px;
    text-align: center;
    margin: 0;
    & h2 {
        font-size: ${fontSize.h2};
        ${MediaMobile} {
            padding: 0 20px;
        }
    }
    ${MediaMobile} {
        width: 100%;
        border-radius: 0;
        padding: 10px;
        height: 650px;
    }
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
    ${MediaMobile} {
        right: 20px;
        top: 10px;
    }
`

const SlotMainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: start;
    ${MediaMobile} {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin: 0 10px;
    }
`

// #endregion CSS

function ModalHourView ({date, setHourViewModal, handleDateChange}) {


    return(
        <MainContainer>
            <ModalContainer>
                <FontIconClose icon={faXmark} onClick={() => setHourViewModal(false)}/>
                <div>
                    <h2>Modifier les créneaux de disponibilités</h2>
                    <SlotMainContainer>
                        <CalendarComponent date={date} handleDateChange={handleDateChange}/>
                        <HourSlotEdited date={date}/>
                    </SlotMainContainer>
                </div>
            </ModalContainer>


        </MainContainer>
    )
}

export default ModalHourView