import styled from "styled-components"
import { colors, fontSize } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"

// #region CSS
const MainContainer = styled.div`
    z-index: 1;
    position: relative;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
`

const ModalContainer = styled.div`
    position: absolute;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 25px;
    background-color: white;
    max-height: 400px;
    width: 700px;
`

const FontIconClose = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
    }
`

const ImageContainer = styled.div`
    height: 400px;
    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    text-align:  center;
    & h2 {
        font-size: ${fontSize.h2};
    }
    & p {
        font-size: ${fontSize.p};
    }
    & h3 {
        font-size: ${fontSize.h3};
    }

`

// #endregion CSS

function ModalConfirmation ({setConfirmationModal}) {

    return(
        <MainContainer>
            <ModalContainer>
                <FontIconClose icon={faXmark} onClick={() => setConfirmationModal(false)}/>
                <TextContainer>
                    <h2>Rendez-vous confirmé !</h2>
                    <p>Merci pour votre confiance, un mail vient de vous être envoyé.</p>
                    <p>Pensez à vérifier les spams si toutefois vous n'avez rien reçu.</p>
                    <p>En cas de modification, n'hésitez pas à m'envoyer un message.</p>
                    <h3>Je vous dis à bientôt</h3>
                </TextContainer>
                <ImageContainer>
                    <img src="./assets/64f0f029c5c3cb8958e1eb7da8ea905b.jpg" alt="confirmation"/>
                </ImageContainer>

            </ModalContainer>


        </MainContainer>
    )
}

export default ModalConfirmation