import { createGlobalStyle } from "styled-components"

export const MediaMobile = "@media (max-width: 768px)"
export const MediaTablet = "@media (max-width: 1024px)"
export const colors = {
    pinkLady: '#f35596',
    brownSugar: '#a9928c',
    pinkPastel: '#eab7ae',
    blueJeans: '#5c7aa2',
    background: '#faf2eb',
    text: '#071125'
    
}
export const fontSize = {
    icon: '18px',
    iconMobile: '16px',
    p: '14px',
    pMobile: '13px'
}

const GlobalStyled = createGlobalStyle`
    * {
        font-family: 'Work Sans', sans-serif;
    }

    body {
        margin: 0;
        background-color: ${colors.background};
    }
    h1, h2, h3 {
        font-family: "Cinzel", serif;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        color: ${colors.pinkLady};
    }
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    p {
        font-size: 14px;
    }
    ${MediaMobile} {
        h1 {
            font-size: 18px;
        }
        h2 {
            font-size: 16px;
        }
        h3 {
            font-size: 14px;
        }
        p {
            font-size: 13px;
        }
    }

`
function GlobalStyle() {
    return <GlobalStyled />
}

export default GlobalStyle