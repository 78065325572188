import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faChevronLeft, faChevronRight, faCalendarDays, faClockRotateLeft} from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "../lib/commonHooks"
import axios from "axios"
import { API_ROUTES } from "../utils/constant"
import { useEffect, useState, useCallback } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { format } from "date-fns"
import Button from "./Button"
import CalendarComponent from "./booking_components/Calendar"
import HourSlot from "./booking_components/HourSlot"
import PrestationList from "./booking_components/PrestationList"
import UserInfo from "./booking_components/UserInfo"
import ModalConfirmation from "./booking_components/ModalConfirmation"
import ModalCalendarView from "./booking_components/ModalCalendarView"
import ModalHourView from "./booking_components/ModalHourSlot"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 650px;
    width: 100vw;
    & p {
        margin: 0;
        ${MediaMobile} {
            text-align: center;
        }
    }
`

const MainTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const MainSousContainer = styled.div`
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    border-radius: 100px;
    width: 1000px;
    margin-top: 20px;
    ${MediaMobile} {
        border-radius: 0;
        width: 100%;
        background-image: linear-gradient(rgba(255, 249, 249, 0.4), rgba(255, 249, 249, 0.4)), url('./assets/f8b390ee6f24b78606e848f2cfee3295.jpg');
        background-size: cover;
        height: 500px;
        display: flex;
    }
`

const ImageContainer = styled.div`
    height: 500px;
    & img {
        height: 100%;
        width: 80%;
        object-fit: cover;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }
    ${MediaMobile} {
        display: none;
    }
`

const FieldMainContainer = styled.form`
    width: 100%;
    ${MediaMobile} {
        background-color: white;
        margin: 30px;
        padding: 5px;
        border-radius: 25px;
    }
`

const FieldContainer = styled(motion.div)`
    position: relative;
    height: 100%;
    ${MediaMobile} {
        & div {
            text-align: center;
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    position: absolute;
    bottom: 60px;
    ${MediaMobile} {
        bottom: 15px;
        width: 100%;
    }
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.background};
    background-color: ${colors.pinkLady};
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
    font-size: ${fontSize.icon};
    border: 1px solid transparent;
    &: hover {
        background-color: white;
        color: ${colors.pinkLady};
        border: 1px solid ${colors.pinkLady};
        cursor: pointer;
        
    }
    &.selected {
        opacity: 0.3;
        cursor: not-allowed;
    }
`
// #endregion CSS

function BookingContainer () {
    const {isAuthenticated} = useAuth()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
    
    const [step, setStep] = useState(1)
    const [direction, setDirection] = useState(1)
    const [date, setDate] = useState("")
    const [hour, setHour] = useState("")
    const [selectedHour, setSelectedHour] = useState(null)
    const [prestation, setPrestation] = useState({})
    const [selectedPrestation, setSelectedPrestation] = useState(null)
    const [userInfo, setUserInfo] = useState({name: "", email: "", phone: ""})
    const [isDisabled, setIsDisabled] = useState(true)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [calendarViewModal, setCalendarViewModal] = useState(false)
    const [hourViewModal, setHourViewModal] = useState(false)

    const onResize = useCallback(() => {
        setIsMobile(window.innerWidth < 768)
    }, [])
    
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return (
            () => {window.removeEventListener('resize', onResize)}
        )
    }, [onResize])

    const bookingData = {
        date, 
        hour, 
        prestation_title: prestation.title, 
        prestation_time: prestation.time, 
        name: userInfo.name, 
        email: userInfo.email, 
        phone: userInfo.phone
    }

    const resetData = () => {
        setDate("")
        setHour("")
        setPrestation({})
        setUserInfo({name: "", email: "", phone: ""})
    }

    // #region Passage de composants
    const variants = {
        enter: (direction) => ({x: direction > 0 ? 100 : -100,opacity: 0}),
        center: { x: 0, opacity: 1 },
        exit: (direction) => ({x: direction > 0 ? -100 : 100,opacity: 0}),
    }
    const variantsMobile = {
        enter: (direction) => ({x: direction > 0 ? 10 : -10,opacity: 0}),
        center: { x: 0, opacity: 1 },
        exit: (direction) => ({x: direction > 0 ? -10 : 10,opacity: 0}),
    }

    const nextStep = () => {
        setDirection(1)
        setStep((prev) => prev + 1)
    }

    const prevStep = () => {
        setDirection(-1)
        setStep((prev) => prev - 1)
    }
    // #endregion Passage de composants

    // #region Gestion des champs
    const customFormatDate = (date) => {
        return format(date, "yyyy-MM-dd")
    }

    const handleDateChange = (newDate) => {
        const formattedDate = customFormatDate(newDate)
        setDate(formattedDate)
    }

    const handleHourChange = (value) => {
        setHour(value)
    }

    const handlePrestationChange = (value) => {
        setPrestation((prev) => ({
            ...prev, 
            title: value.title,
            time: value.time 
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setUserInfo((prev) => ({...prev, [name] : value}))
    } 
    // #endregion Gestion des champs
   
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(isDisabled) {
            return
        }
        try {
            const response = await axios.post(API_ROUTES.BOOKING, {...bookingData})
            if(response.status === 201) {
                setTimeout(() => {
                    setConfirmationModal(true)
                    resetData()
                    setStep(1)                
                }, 1000)
            } else {
                console.error('Unexpected status code:', response.status)
            }
        } catch(err) {
            console.error(err)
        }
    }

    useEffect(() => {
        const validateForm = () => {
            switch (step) {
                case 1:
                    return date !== ""
                case 2:
                    return hour !== ""
                case 3:
                    return prestation !== ""
                case 4:
                    return userInfo.name.trim() !== "" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userInfo.email.trim()) && /^\d{10}$/i.test(userInfo.phone.trim())
                default:
                    return false
            }
        }
        setIsDisabled(!validateForm())
    }, [date, hour, prestation, userInfo, step])

    return(
        <MainContainer>
            <MainTitleContainer>
                <h2>Prendre un rendez-vous</h2>
                {isAuthenticated && (
                    <>
                        <FontIcon icon={faCalendarDays} onClick={() => setCalendarViewModal(true)}/>
                        <FontIcon icon={faClockRotateLeft} onClick={() => setHourViewModal(true)}/>                    
                    </>
                )}
            </MainTitleContainer>
            <p>Pour réservez un créneau avant qu'il ne soit trop tard, c'est ici que ça se passe !</p>
            <MainSousContainer>
                <ImageContainer>
                    <img src="./assets/f8b390ee6f24b78606e848f2cfee3295.jpg" alt="renfort" />
                </ImageContainer>
                <FieldMainContainer onSubmit={handleSubmit}>
                    <AnimatePresence custom={direction} mode="wait">
                        {step === 1 && (
                            <FieldContainer
                                key="step1"
                                variants={isMobile ? variantsMobile : variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >
                                <div>
                                    <h3>calendrier</h3>
                                    <CalendarComponent 
                                        handleDateChange={handleDateChange}
                                        date={date}       
                                    />
                                </div>
                                <ButtonContainer>
                                    <div></div>                        
                                    <FontIcon icon={faChevronRight} disabled={isDisabled} onClick={!isDisabled ? nextStep : null} className={isDisabled ? "selected" : ""}/>                        
                                </ButtonContainer>                  
                            </FieldContainer>
                        )}
                        {step === 2 && (
                            <FieldContainer
                                key="step2"
                                variants={isMobile ? variantsMobile : variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >
                                <div>
                                    <h3>horaires</h3>
                                    <HourSlot 
                                        date={date}
                                        name="hour" 
                                        value={hour}
                                        handleHourChange={handleHourChange}
                                        selectedHour={selectedHour}
                                        setSelectedHour={setSelectedHour}
                                    />
                                </div>
                                <ButtonContainer>
                                    <FontIcon icon={faChevronLeft} onClick={prevStep}/>                        
                                    <FontIcon icon={faChevronRight} disabled={isDisabled} onClick={!isDisabled ? nextStep : null} className={isDisabled ? "selected" : ""}/>                        
                                </ButtonContainer>                  
                            </FieldContainer>
                        )}
                        {step === 3 && (
                            <FieldContainer
                                key="step3"
                                variants={isMobile ? variantsMobile : variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >
                                <div>
                                    <h3>prestation</h3>
                                    <PrestationList 
                                        name={{ title: "prestation_title", time: "prestation_time" }}
                                        value={{ title: prestation.title, time: prestation.time }}
                                        handlePrestationChange={handlePrestationChange}
                                        selectedPrestation={selectedPrestation}
                                        setSelectedPrestation={setSelectedPrestation}
                                        key={`prestation-${{ title: prestation.title, time: prestation.time }}`}
                                        
                                    />
                                </div>
                                <ButtonContainer>
                                    <FontIcon icon={faChevronLeft} onClick={prevStep}/>                        
                                    <FontIcon icon={faChevronRight} disabled={isDisabled} onClick={!isDisabled ? nextStep : null} className={isDisabled ? "selected" : ""}/>                        
                                </ButtonContainer>
                            </FieldContainer>
                        )}
                        {step === 4 && (
                            <FieldContainer
                                key="step4"
                                variants={isMobile ? variantsMobile : variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                                custom={direction}
                            >                         
                                <div>
                                    <h3>coordonnées</h3>
                                    <UserInfo 
                                        userInfo={userInfo}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <ButtonContainer>
                                    <FontIcon icon={faChevronLeft} onClick={prevStep}/>                        
                                    <Button 
                                        name="Valider"
                                        type="submit"
                                        disabled={isDisabled} 
                                        className={isDisabled ? "selected" : "" }
            
                                    />
                                </ButtonContainer>
                            </FieldContainer>
                        )}
                    </AnimatePresence>
                </FieldMainContainer>
            </MainSousContainer>
            {confirmationModal && (
                <ModalConfirmation 
                    setConfirmationModal={setConfirmationModal}
                />
            )}
            {calendarViewModal && (
                <ModalCalendarView
                    setCalendarViewModal={setCalendarViewModal}
                />
            )}
            {hourViewModal && (
                <ModalHourView
                    setHourViewModal={setHourViewModal}
                    handleDateChange={handleDateChange}
                    date={date}
                />
            )}
        </MainContainer>
    )
}

export default BookingContainer