import styled from "styled-components"
import { MediaMobile } from "../../style/GlobalStyle"

const MainContainer = styled.ul`
    margin: 20px;
    padding: 0;
    ${MediaMobile} {
        margin: 0;
        margin-left: 10px;
    }
`
const PriceContainer = styled.li`
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 80px;
    & p {
        text-align: center;    
    }
    ${MediaMobile} {
        grid-gap: 10px;
    }
`

function ViewPrices ({data}) {
    return(
        <MainContainer>
        {data.map((item, index) => 
            <PriceContainer key={`${index}-${item}`}>
                <p>{item.title}</p> 
                <p>{item.time} min</p> 
                <p>{item.price}€</p>
            </PriceContainer>
        )}
        </MainContainer>

    )
}

export default ViewPrices