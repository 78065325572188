import styled from "styled-components"
import { colors, MediaMobile } from "../style/GlobalStyle"

// #region CSS
const MainContainer = styled.div`
    width: 100vw;
    height: 600px;
    background-color: white;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${MediaMobile} {
        display: flex;
        flex-direction: column;
    }
`

const BackgroundImage = styled.img`
    height: 600px;
    object-position: center;
    opacity: 0.9;
    width: 70%;
    object-fit: cover;
    ${MediaMobile} {
        margin: 0;
        width: 100%;
        height: 300px;
    }
`

const ProfilMAinContainer = styled.div`
    text-align: center;
    width: 60%;
    margin: 50px;
    padding: 50px;
    ${MediaMobile} {
        margin: 0;
        padding: 0;
        width: 100%;
    }
`

const ProfilImage = styled.img`
    position: absolute;
    left: 47vh;
    top: 3vh;
    border-radius: 25%;
    height: 500px;
    ${MediaMobile} {
        display: none;
    }
`

const WaveBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    & svg {
        position: relative;
        display: block;
        width: 100vw;
        transform: rotate(180deg);
        & path {
            fill: ${colors.background};
        }
    }
`
// #endregion CSS

function AboutContainer () {
    return(
        <MainContainer>
            <BackgroundImage src="./assets/397a8100a5aeab321fac936aa8616f71.jpg" alt="fond arrière plan"/>
            <ProfilMAinContainer>
                <ProfilImage src="./assets/profilvolteface.png" alt="profil de volteface"/>
                <h2>A propos</h2>
                <div>
                    <p>Passionnée par l’univers de la beauté et du nail art, je suis prothésiste ongulaire à mon compte depuis plusieurs années. </p>
                    <p>Dans mon salon cosy installé chez moi, je chouchoute vos ongles avec précision et bonne humeur. Ici, on papote, on rigole, et surtout, on ressort avec des mains sublimes ! </p>
                    <p>Que vous aimiez le naturel ou l’extravagance, je mets mon savoir-faire au service de vos envies.</p>
                    <p>Alors, prête à donner un coup d’éclat à vos ongles ?</p>
                </div>
            </ProfilMAinContainer>

             <WaveBottom>
                <svg viewBox="0 10 1200 120" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0,60 C200,120 600,0 1200,45 L1200,0 L0,0 Z" ></path>
                </svg>
            </WaveBottom>
        </MainContainer>
    )
}

export default AboutContainer