import styled from "styled-components"
import { colors, MediaMobile } from "../../style/GlobalStyle"
import { useEffect, useState } from "react"
import axios from "axios"
import { API_ROUTES } from "../../utils/constant"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    ${MediaMobile} {
        margin-top: 50px;
        padding-top: 0;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    & div {
        font-size: 14px;
    }
`

const Button = styled.label`
    right: 100px;
    position: absolute;
    border-radius: 25px;
    background-color: ${colors.background};
    border: none;
    padding: 5px 20px;
    font-size: 14px;
    &: hover{
        background-color: ${colors.blueJeans};
        color: white;
        cursor: pointer;
    }
    &.selected {
        background-color: ${colors.blueJeans};
        color: white;
    }
    ${MediaMobile} {
        right: 5px;
    }

`

const Input = styled.input`
    position: absolute;
    opacity: 0;
`
// #endregion CSS

function PrestationList ({handlePrestationChange, name, selectedPrestation, setSelectedPrestation}) {
    const [data, setData] = useState([])

    const getFetchData = async () => {
        try {
            const response = await axios.get(API_ROUTES.PRICES, {withCredentials: true})
            setData(response.data || [])
        } catch(err) {
            console.error(err)
        }
    }

    const handleSelectProduct = (e) => {
        const selectedObject = data.find((i) => i.title === e.target.value)
        if (!selectedObject) {
            return
        }
        const newValue = {
            title: selectedObject.title,
            time: selectedObject.time
          }
        setSelectedPrestation(newValue)
        handlePrestationChange(newValue)
    }

    useEffect(() => {
        getFetchData()
    }, [])

    return(
        <MainContainer>
            {data.map((item, index) => 
                <Container>
                    <div 
                        key={`title-${item}-${index}`} 
                        >
                        {item.title}
                    </div>
                    <Button 
                        className={selectedPrestation && selectedPrestation.title === item.title ? "selected" : "" }
                    >
                        choisir
                        <Input 
                            type="radio" 
                            name={name.title} 
                            value={item.title} 
                            onChange={handleSelectProduct}
                        />
                    </Button>
                </Container>
            )}
        </MainContainer>
    )
}

export default PrestationList