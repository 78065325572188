import axios from "axios"
import { API_URL } from "../utils/constant"
import { useAuth } from "./commonHooks"

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if(token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => Promise.reject(error)
)

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const {handleLogout} = useAuth()
        if(error.response && error.response.status === 401) {
            console.error("Token expiré. Déconnexion")
            handleLogout()
        }
        return Promise.reject(error)
    }
)

export default api