import styled from "styled-components"
import { colors, MediaMobile } from "../../style/GlobalStyle"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../../utils/constant"
import axios from "axios"

// #region CSS
const TimeSheetContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
    ${MediaMobile} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5px;
        padding-top: 0;
        justify-items: center;
        margin-top: 50px;
    }
`

const TimeSheet = styled.label`
    position : relative;
    text-align: center;
    border-radius: 25px;
    padding: 10px;
    width: 80px;
    height: 15px;
    font-size: 13px;
    background-color: ${colors.background};
    &: hover{
        background-color: ${colors.blueJeans};
        color: white;
        cursor: pointer;
    }
    &.selected {
        background-color: ${colors.blueJeans};
        color: white;
    }
    ${MediaMobile} {
        width: 50px;
    }
`
const TimeSheetInput = styled.input`
    position: absolute;
    opacity: 0;
`
// #endregion CSS

const timeSheet = [
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", 
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", 
    "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30"
]

function HourSlot ({ date, name, handleHourChange, selectedHour, setSelectedHour}) {
    const [data, setData] = useState([])
    const [tags, setTags] = useState([])

    const getFetchData = async () => {
        try {
            const response = await axios.get(`${API_ROUTES.BOOKING}/${date}`, {withCredentials: true})
            setData(response.data || [])
            const availableHours = timeSheet.filter((hour) => !response.data.includes(hour))
            setTags(availableHours) 
        } catch(err) {
            console.error(err)
        }
    }

    console.log('data côté client', data) // Donne les créneau indisponilbles
    console.log('tag côté client', tags) // Montre ce que les clients voient
    // Gestion des utilisateurs
    const deletedHourStrings = data.map(hourObj => hourObj.hour)
    const remainingHours = tags.filter(hour => !deletedHourStrings.includes(hour))

    const handleSelectProduct = (e) => {
        const selectedObject = remainingHours.find((i) => i === e.target.value)
        setSelectedHour(selectedObject)
        handleHourChange(selectedObject)
    }
        
    useEffect(() => {
        getFetchData()
    }, [])

    return(
        <TimeSheetContainer>
            {!date ? (
                tags.map((item, index) => 
                    <TimeSheet key={`tagItem-${index}-${item}`}>
                        {item}
                    </TimeSheet>
                )
            ) : (
                remainingHours.map((item, index) => 
                    <TimeSheet 
                        key={`tagItem-${index}-${item}`}
                        className={selectedHour === item ? "selected" : "" } 
                        name={name}
                    >
                        {item}
                        <TimeSheetInput 
                            type="radio" 
                            value={item} 
                            onChange={handleSelectProduct}
                            checked={selectedHour === item}
                        />
                    </TimeSheet>
                )
            )}
        </TimeSheetContainer>
    )
}

export default HourSlot