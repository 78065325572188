import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

// #region CSS
const MainContainer = styled.form`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 5px 0;
    ${MediaMobile} {
        width: 100%;
    }
`

const Input = styled.input`
    text-align: center;
    outline: none;
    background-color: ${colors.background};
    border-radius: 25px;
    border: none;
    padding: 5px 10px;
    ${MediaMobile} {
        padding: 5px;
        width: 100%;
        height: 18px;
    }
`

const IconButton = styled.button`
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    text-align: right;
`

const FontIcon = styled(FontAwesomeIcon)`
    background-color: ${colors.pinkLady};
    color: white;
    font-size: ${fontSize.icon};
    padding: 10px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    &: hover{
        background-color: white;
        color: ${colors.pinkLady};
        cursor: pointer;
        border: 1px solid ${colors.pinkLady};
    }
    ${MediaMobile} {
        padding: 5px;
    }
`
// #endregion CSS


function Edit({index, item, inputList, setInputList, handleUpdate}) {
    function handleInput(e) {
        const {value, name} = e.target
        const newInput = inputList.map((i) => (
            i._id === item._id ? {...i, [name] : value} : i
        ))
        setInputList(newInput)
    }

    return(
        <MainContainer onSubmit={handleUpdate} key={`price-${index}-${item._id}`}>
            <Input type="text" name="title" value={item.title} onChange={handleInput} />
            <Input type="text" name="time" value={item.time} onChange={handleInput}/>
            <Input type="text" name="price" value={item.price} onChange={handleInput}/>
            <IconButton type="submit"><FontIcon icon={faCheck} /></IconButton>
        </MainContainer>
    )
}

export default Edit