import styled from "styled-components"
import { colors, MediaMobile } from "../../style/GlobalStyle"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../../utils/constant"
import axios from "axios"
import Button from "../Button"

const MainContainer = styled.div`
    margin: 20px 0;
    align-content: center;
    & h2 {
        text-align: center;
    }
`

const FormLabelContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const InputContainer = styled.input`
    width: 400px;
    height: 30px;
    border-radius: 25px;
    padding: 5px 20px;
    background-color: ${colors.background};
    border: 1px solid transparent;
    outline: none;
    position: relative;
    ${MediaMobile} {
        width: 300px;
    }
`


function ForgotPassword () {
    const [email, setEmail] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [notification, setNotification] = useState({ error: false, message: '' })

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const response = await axios.post(API_ROUTES.RESET_PASSWORD, {email}, {withCredentials: true})
            if(response.status === 201) {
                const succesMessage = <p>Un email de réinitialisation a été envoyé</p>
                setNotification({error: false, message: succesMessage})
                setEmail('')
            } else {
                const errorMessage = <p>Une erreur est survenue. Veuillez réessayer</p>
                setNotification({error: true, message: errorMessage})
            }
        } catch (err) {
            console.error(err)
            setNotification({error: true, message: '* Une erreur est survenue'})
        }
    }

    useEffect(() => {
        const validateForm = () => {
            const hasInformation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
            return hasInformation
        }    
        setIsDisabled(!validateForm())
    }, [email])
    

    return(
        <MainContainer>
            <h2>Réinitialiser votre mot de passe</h2>
            <FormLabelContainer onSubmit={handleSubmit}>
                <InputContainer 
                    type="email"
                    placeholder="Votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <Button 
                    type="submit"
                    name="Envoyer"
                    disabled={isDisabled} 
                    className={isDisabled ? "selected" : "" }

                />
            </FormLabelContainer>
            <div style={notification.error === true ? {color: `${colors.pinkLady}`} : {color: `${colors.blueJeans}`}}>{notification.message}</div>
        </MainContainer>
    )
}

export default ForgotPassword