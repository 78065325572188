import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"


function ResetPassword () {
    const {token} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            navigate(`/?reset=true&token=${token}`, {replace: true})
        }
    }, [token, navigate])
    
    return null
}

export default ResetPassword