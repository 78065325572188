import styled from "styled-components"
import { colors, fontSize, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import api from "../../lib/api"
import { API_ROUTES } from "../../utils/constant"
import { getAuthenticatedUser } from "../../lib/common"
import Edit from "./Edit"

// #region CSS
const MainContainer = styled.div`
    padding: 0;
    & ul {
        padding: 0;
    }
    ${MediaMobile} {
        width: 100%;
    }
`
const PriceContainer = styled.li`
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    & p {
        text-align: center;    
    }
    ${MediaMobile} {
        grid-gap: 5px;
        width: 100%;
    }
`

const FormMainContainer = styled.form`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
`

const IconContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    ${MediaMobile} {
        gap: 5px;
    }
`

const IconButton = styled.button`
    padding: 0;
    border: none;
    background-color: transparent;
    text-align: right;

`

const FontIcon = styled(FontAwesomeIcon)`
    background-color: ${colors.pinkLady};
    color: white;
    font-size: ${fontSize.icon};
    padding: 10px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    &: hover{
        background-color: white;
        color: ${colors.pinkLady};
        cursor: pointer;
        border: 1px solid ${colors.pinkLady};
    }
    ${MediaMobile} {
        padding: 5px;
    }
`

const Input = styled.input`
    text-align: center;
    outline: none;
    background-color: ${colors.background};
    border-radius: 25px;
    border: none;
    padding: 5px 10px;
    ${MediaMobile} {
        padding: 5px;
        width: 100%;
    }
`
// #endregion CSS

function EditedPrices ({data, setData, setUpdateUI}) {
    const initValue = {title: "", time: "", price: ""}
    const [updateState, setUpdateState] = useState(null)
    const [input, setInput] = useState(initValue)

    // #region Ajout d'un item
    const handleChange = (e) => {
        const {value, name} = e.target
        setInput((preve) => {
            return{...preve, [name] : value}
        })
    }

    const handleCreated = async (e) => {
        e.preventDefault(e)
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            await api.post(API_ROUTES.PRICES, {...input}, {withCredentials: true})        
            setInput(initValue)
            setUpdateUI((prevState) => !prevState)
        } catch(err) {
            console.error(err)
        }
    }
    // #endregion Ajout d'un item

    // #region Modification d'un item
    const handleEdited = async (id) => {
        setUpdateState(id)
    }
    console.log('id selected', updateState)

    const handleUpdated = async (e) => {
        e.preventDefault()
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            const itemToUpdate = data.find((item) => item._id === updateState)
            console.log('itemToUpdate:', itemToUpdate);
            await api.put(`${API_ROUTES.PRICES}/${updateState}`, itemToUpdate, {withCredentials: true})
            setUpdateState(null)
            setInput(initValue)

        } catch(err) {
            console.error(err)
        }
    }
    // #endregion Modification d'un item

    // #region Suppression d'un item
    const handleDeleted = async (id) => {
        try {
            const {authenticated, user} = await getAuthenticatedUser()
            if(!authenticated && !user?.token) {
                console.error('User not authenticated or token missing')
                return
            }
            await api.delete(`${API_ROUTES.PRICES}/${id}`, {withCredentials: true})
            setUpdateUI((prevState) => !prevState)
        } catch(err) {
            console.error(err)
        }
    }
    // #endregion Suppression d'un item

    return(
        <MainContainer>
            <ul>
            {data.map((item, index) => 
                updateState === item._id ? (
                    <Edit 
                        index={index}
                        item={item} 
                        inputList={data} 
                        setInputList={setData} 
                        handleUpdate={handleUpdated}
                    />
                ) : (
                    <PriceContainer key={`${index}-${item}`}>
                        <p>{item.title}</p> 
                        <p>{item.time} min</p> 
                        <p>{item.price}€</p>
                        <IconContainer>
                            <FontIcon icon={faXmark} onClick={() => handleDeleted(item._id)}/>
                            <FontIcon icon={faPencil} onClick={() => handleEdited(item._id)}/>
                        </IconContainer>
                    </PriceContainer>
                )
            )}
            </ul>
            <FormMainContainer onSubmit={handleCreated}>
                <Input type="text" name="title" value={input.title} placeholder="Type de prestation" onChange={handleChange}/>
                <Input type="text" name="time" value={input.time} placeholder="Durée" onChange={handleChange}/>
                <Input type="text" name="price" value={input.price} placeholder="Prix" onChange={handleChange}/>
                <IconButton type="submit"><FontIcon icon={faPlus}/></IconButton>
            </FormMainContainer>

        </MainContainer>

    )
}

export default EditedPrices