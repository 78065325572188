import React, { useState } from "react"
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { AuthProvider } from "./lib/commonHooks"
import HomePage from "./pages/HomePage"
import Header from "./layouts/Header"
import Footer from "./layouts/Footer"
import GlobalStyle from "./style/GlobalStyle"
import ResetPassword from "./components/connexion_components/ResetPassword"


function App() {
  const [openmodal, setopenmodal] = useState(false)

  return (
    <Router>
      <AuthProvider>
      <GlobalStyle/>
      <Header />
        <Routes>
          <Route path="/" element={<HomePage openModal={openmodal} setOpenModal={setopenmodal}/>}/>
          <Route exact path='/:token' element={<ResetPassword />}/>
        </Routes>
      <Footer setOpenModal={setopenmodal} />
      </AuthProvider>
    </Router>
  )
}

export default App
