import styled from "styled-components"
import { colors, MediaMobile } from "../../style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faAt, faMobile } from "@fortawesome/free-solid-svg-icons"

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
    ${MediaMobile} {
        margin-top: 50px;
        padding-top: 0;
    }

`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    padding: 15px 30px;
    gap: 20px;
    width: 350px;
    background-color: ${colors.background};
    & p {
        margin: 0;
        color: ${colors.text};
    }
    ${MediaMobile} {
        width: auto;
    }
`

const Input = styled.input`
    outline: none;
    background-color: transparent;
    border: 0;
    width: 100%;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.text};
`

function UserInfo ({userInfo, handleChange}) {
    return(
        <MainContainer>
            <InputContainer>
                <FontIcon icon={faUser}/>
                <Input 
                    type="text" 
                    placeholder="Nom & Prénom" 
                    name="name" 
                    value={userInfo.name}
                    onChange={handleChange}
                />
            </InputContainer>
            <InputContainer>
                <FontIcon icon={faAt}/>
                <Input 
                    type="email" 
                    placeholder="E-mail" 
                    name="email" 
                    value={userInfo.email} 
                    onChange={handleChange}
                />
            </InputContainer>
            <InputContainer>
                <FontIcon icon={faMobile}/>
                <Input 
                    type="text" 
                    placeholder="Téléphone" 
                    name="phone" 
                    value={userInfo.phone} 
                    onChange={handleChange}
                />
            </InputContainer>
        </MainContainer>

    )
}

export default UserInfo